import { useState } from 'react';
import Table from 'react-bootstrap/esm/Table';
import Button from 'react-bootstrap/esm/Button';
import { useGetSellQuery, useEditHardItemsMutation, useGetHardItemsQuery } from './inventoryApiSlice'

const InventoryHard = () => {

    
    const [ triggerHard ] = useEditHardItemsMutation()
    const [ query, setQuery ] = useState(' ')
    const { data: hardItemsList } = useGetHardItemsQuery()
    const {
        data: cat,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSellQuery()

    const trigger = async (item) => {
        const toHard = new Array(item)
        const res = await triggerHard(toHard)
        console.log(res)
    }


    const inventory = cat?.filter(item => `${item.name.toLowerCase()} ${item.variation_1.toLowerCase()} ${item.variation_2.toLowerCase()} ${item.variation_3.toLowerCase()}`
                    .includes(query))
                    .map((element, index) => {
                    return (
                        <tr key={index.toString()}>
                            <td>{element.name}</td>
                            <td>{(element.variation_1 + ' ' + element.variation_2 + ' ' + element.variation_3)}</td>
                            <td>
                                <Button variant='primary' onClick={e => trigger(element)}>เพิ่ม</Button>
                            </td>
                        </tr>
        )
    })

    const hard = hardItemsList?.map((item, index) => {
        return(
            <tr key={index}>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>
                    <Button variant='danger' onClick={e => trigger(item)}>ลบ</Button>
                </td>
            </tr>
        )
    })

    let content;
    if (isLoading) {
        content = <h1>"Loading...</h1>
    } else if (isSuccess) {
        content = (
            <section className='container-fluid d-flex justify-content-around text-center'>
                <div className='col-5'>
                    <h1>รายการสินค้า</h1>
                    <Table style={{color:"white"}} responsive='sm'>
                        <thead>
                            <tr>
                                <td className='col-1' >รายการ</td>
                                <td className='col-1' >คำอธิบาย</td>
                                <td className='col-1' >
                                    <input type='text' placeholder='ค้นหา' onChange={e => {setQuery((e.target.value).toLowerCase())}}></input>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {inventory}
                        </tbody>
                    </Table>
                </div>
                <div className='col-5'>
                    <h1>สินค้ายิงยาก</h1>
                <Table style={{color:"white"}} responsive='sm'>
                        <thead>
                            <tr>
                                <td>รายการ</td>
                                <td>คำอธิบาย</td>
                                <td>ACTION</td>
                            </tr>
                        </thead>
                        <tbody>
                            {hard}
                        </tbody>
                    </Table>
                </div>

            </section>
        )
    } else if (isError) {
        content = <p>{JSON.stringify(error)}</p>
    }
    
  return (
    content
  )

}

export default InventoryHard