import { useState, useEffect } from "react";
import { useDeleteBulkMutation, useGetBulkItemsQuery, useGetItemByBarcodeQuery, useDeleteItemMutation, useIncrementItemMutation, useSellItemMutation } from "./inventoryApiSlice";
import Table from 'react-bootstrap/esm/Table';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import AddBulk from './AddBulk'

const BulkItems = () => {
    const [ query, setQuery ] = useState('')
    const [ qty, setQty ] = useState(0)
    const [ perPack, setPerPack] = useState(0)
    const [ deleteItem ] = useDeleteItemMutation()
    const [ deleteBulk ] = useDeleteBulkMutation()
    const [ insert ] = useIncrementItemMutation()
    const [ sell ] = useSellItemMutation()
    const [ bulk, setBulk ] = useState({
        name: '',
        BulkBarcode: '',
        quantity: 0,
        price: 0,
        ItemBarcode: 'null'
    })
    const { data } = useGetItemByBarcodeQuery(bulk.ItemBarcode)
    const { data: sellItem } = useGetItemByBarcodeQuery(bulk.BulkBarcode)

    const handleBulk = async (e) => {
        const direction = Number(e.target.value)
        const times = qty
        const individual = perPack
        const item = data
        if (direction === 1) {
            const inc = {
                "barcode": item.barcode,
                "direction": direction * individual
            }
            for (let i = 0; i< times; i++) {
                await insert(inc)
            }
        } else if (direction === -1) {
            let order = []
            for (let i = 0; i < times; i++) {
                order.push(sellItem)
            }
            await sell(order)
        }


    }

    const handleDelete = async () => {
        const toDel = bulk
        await deleteBulk(toDel.BulkBarcode)
        await deleteItem(toDel.BulkItems)
        console.log("Deleted ", bulk.name)
    }

    const handleQty = e => {
        const inc = Number(e.target.value)
        const newQty = qty + inc
        if (newQty < 0) {
            setQty(0)
        } else {
            setQty(newQty)
        }
    }
    
    const handleLookUp = async (item) => {
        if (bulk === item) {
            setBulk({
                name: '',
                BulkBarcode: '',    
                quantity: 0,
                price: 0,
                ItemBarcode: null
            })
            setPerPack(0)
            setQty(0)
        } else {
            setBulk(item)
            setPerPack(item.quantity)
            console.log(bulk)
        }
    }          

    const {
        data: items,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetBulkItemsQuery()

    let content
    if (isLoading) {
        content = <p>"Loading...</p>
    } else if (isSuccess) {
        content = (
            <>
                <Container fluid='true' className="justify-content-around text-center d-flex">
                    <Container>
                        <Table className='col-6' style={{color:"white"}} hover size='lg' responsive='sm'>
                            <thead>
                                <tr style={{position:"sticky"}}>
                                    <td className='col-3'>รายการ</td>
                                    <td className='col-1'>ราคา/ลัง</td>
                                    <td className='col-1'>จำนวน/ลัง</td>
                                    <td className='col-1'>
                                        <input type='text' placeholder='ค้นหา' onChange={e => {setQuery((e.target.value).toLowerCase())}}></input>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {items? items.filter(item => `${item.name.toLowerCase()}}`
                                    .includes(query))
                                    .map((element, index) => {
                                    return (
                                <tr key={index.toString()}>
                                    <td>{element.name}</td>
                                    <td>{element.price}</td>
                                    <td>{element.quantity}</td>
                                    <td>
                                        <Button variant='outline-danger' onClick={e => handleLookUp(element)}>เลือก</Button>
                                    </td>
                                </tr> 
                                )}): []}
                            </tbody>
                        </Table>
                    </Container>
                    <Container className="col-3 text-center fs-5 m-5">
                        {data?.barcode === 'null'? <h1>เลือกรายการ</h1>: 
                            <>
                                <Row>
                                    <Col>
                                        ชื่อ: 
                                    </Col>
                                    <Col>
                                        {data?.name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        คำอธิบาย: 
                                    </Col>
                                    <Col>
                                        {(data?.variation_1 + ' ' + data?.variation_2 + ' ' + data?.variation_3)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        ทุน: 
                                    </Col>
                                    <Col>
                                        {data?.cost}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        ราคา: 
                                    </Col>
                                    <Col>
                                        {data?.price}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        จำนวน: 
                                    </Col>
                                    <Col>
                                        {data?.quantity}
                                    </Col>
                                </Row>
                            </>
                        }
                        <Container className="my-5 text-start">
                            {
                                <Container >
                                        <h1 className="text-center my-5">{perPack} ชิ้นต่อลัง</h1>
                                    <Container className="d-flex justify-content-between align-middle">
                                        <Button value={-1} style={{width:'50px', height:'50px', alignSelf:'auto'}} onClick={handleQty} variant="danger">-</Button>
                                        <h1>{qty}</h1>
                                        <Button value={1} style={{width:'50px', height:'50px', alignSelf:'auto'}} onClick={handleQty} variant="primary">+</Button>
                                    </Container>
                                    <Container className="d-flex justify-content-between mt-5">
                                        <Button style={{fontSize:'2em'}} value={-1} onClick={handleBulk} variant="light">ขาย</Button>
                                        <Button style={{fontSize:'2em'}} value={1} onClick={handleBulk} variant="warning">เพิ่ม</Button>
                                    </Container>
                                    <Container className="text-center my-5">
                                        <Button onClick={handleDelete} className="d-flex" variant="danger" style={{fontSize:"1em", width:'100%', height:'100%', justifyContent:'center', textAlign:'center'}}>ลบรายการ</Button>
                                    </Container>
                                    <Container className="text-center my-5">
                                        <AddBulk />
                                    </Container>
                                </Container>
                            }
                        </Container>
                    </Container>
                </Container>
            </>
        )
    } else if (isError) {
        content = <p>{JSON.stringify(error)}</p>
    }

return content

}

export default BulkItems