import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useCreateBulkMutation, useAddInventoryMutation } from './inventoryApiSlice';

function AddBulk() {
  const [show, setShow] = useState(false);
  const [ createItem ] = useAddInventoryMutation()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ bulk, setBulk ] = useState({
    name: '',
    BulkBarcode: '',
    quantity: 0,
    price: 0,
    ItemBarcode: ''
})
  const [ createBulk ] = useCreateBulkMutation()

  const handleSubmit = async e => {
    e.preventDefault()
    await createBulk(bulk)
    await createItem({
      "name": bulk.name,
      "barcode": bulk.BulkBarcode,
      "variation_1": "สินค้ายกแพ็ค",
      "variation_2": bulk.ItemBarcode,
      "variation_3": bulk.quantity,
      "cost": 0,
      "type": "b",
      "price": bulk.price,
      "category": 0,
      "quantity": 0
    })
  }

  const onChange = (e) => {
    const {name, value} = e.target
    if (e.target.name === ("quantity" || "price")) {
      setBulk((prevstate) => ({
        ...prevstate,
        [name]: parseInt(value)
      }))
    } else {
    setBulk((prevstate) => ({
      ...prevstate,
      [name]: value
    }))
  }
  }

  return (
    <>
      <Button className="d-flex" style={{fontSize:"1em", width:'100%', height:'100%', justifyContent:'center', textAlign:'center'}} variant="primary" onClick={handleShow}>
        เพิ่มรายการ
      </Button>

      <Modal style={{color:'black'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>เพิ่มรายการสินค้ายกลัง</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>รายการ</Form.Label>
              <Form.Control required name='name' type="text" onChange={onChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="BulkBarcode">
              <Form.Label>Barcode ลัง</Form.Label>
              <Form.Control required name='BulkBarcode' type="text" onChange={onChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="quantity">
              <Form.Label>จำนวนต่อลัง</Form.Label>
              <Form.Control required name='quantity' type="number" onChange={onChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="price">
              <Form.Label>ราคาต่อลัง</Form.Label>
              <Form.Control required name='price' type="number" onChange={onChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="ItemBarcode">
              <Form.Label>Barcode สินค้าแยกชิ้น</Form.Label>
              <Form.Control required name='ItemBarcode' type="text" onChange={onChange}/>
            </Form.Group>
            <Container className='d-flex justify-content-around'>
              <Button variant="danger" onClick={handleClose}>
                ยกเลิก
              </Button>
              <Button variant="success" type='submit' onClick={handleClose}>
                ยืนยัน
              </Button>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddBulk