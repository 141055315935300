import { useState, useEffect } from 'react'

function Clock() {

    const [time, setTime] = useState(new Date())

    useEffect(() => {
        setInterval(() => {
            setTime(new Date())
        }, 1000);
    }, [time])

    return (
        <span style={{color: '#7fff00'}}>
            {time.toLocaleTimeString('en-US', { hour12: false })}
        </span>
    )
}

export default Clock