import { useState } from 'react'
import { useGetInventoryQuery, useIncrementItemMutation } from "./inventoryApiSlice";
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const InventoryList = () => {
    const [ query, setQuery ] = useState('')
    const [ increment ] = useIncrementItemMutation()

    const handleIncrement = async (barcode, e) => {
        const item = {
            "barcode": barcode,
            "direction": e.target.value
        }
        await increment(item)
    }

    const {
        data: items,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetInventoryQuery()

    let content
    if (isLoading) {
        content = <p>"Loading...</p>
    } else if (isSuccess) {
        content = (
            <>
                <Table style={{color:"white"}} hover responsive='sm'>
                    <thead>
                        <tr>
                            <td className='col-1'>#</td>
                            <td className='col-1'>รายการ</td>
                            <td className='col-5'>คำอธิบาย</td>
                            <td className='col-1'>ทุน</td>
                            <td className='col-1'>ราคา</td>
                            <td className='col-1'>จำนวน</td>
                            <td className='col-1'><input type='text' autoFocus placeholder='ค้นหา' onChange={e => {setQuery((e.target.value).toLowerCase())}}></input></td>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.filter(item => `${item?.name?.toLowerCase()} ${item?.variation_1?.toLowerCase()} ${item?.variation_2?.toLowerCase()} ${item?.variation_3?.toLowerCase()}`
                            .includes(query))
                            .map((element, index) => {
                            return (
                           <tr key={index.toString()}>
                            <td>{index+1}</td>
                            <td>{element.name}</td>
                            <td>{(element.variation_1 + ' ' + element.variation_2 + ' ' + element.variation_3)}</td>
                            <td>{element.cost}</td>
                            <td>{element.price}</td>
                            <td>{element.quantity}</td>
                            <td>
                                <Button variant='outline-danger' value={-1} onClick={e => handleIncrement(element.barcode, e)}>-</Button>
                                <Link to={`/edit/${element.barcode}`}><Button variant="outline-light">แก้ไข</Button></Link>
                                <Button variant='outline-success' value={1} onClick={e => handleIncrement(element.barcode, e)}>+</Button>
                            </td>
                         </tr> 
                        )})}
                    </tbody>
                </Table>
            </>
        )
    } else if (isError) {
        content = <p>{JSON.stringify(error)}</p>
    }

return content

}

export default InventoryList