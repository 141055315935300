import { useDeleteItemMutation, useEditItemByBarcodeMutation, useGetItemByBarcodeQuery } from "./inventoryApiSlice";
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useParams, useNavigate } from 'react-router-dom';

const InventoryEdit = () => {

  const navigate = useNavigate()
  const [ editItem ] =useEditItemByBarcodeMutation()
  const [ deleteItem ] = useDeleteItemMutation()

  const barcode = Object.values(useParams())[1]
  
  const { 
    data,
    isLoading,
    isSuccess,
    isError,
    error } = useGetItemByBarcodeQuery(barcode)

  const [item, setItem] = useState({
    name: "",
    category:"",
    barcode: "",
    variation_1: "",
    variation_2: "",
    variation_3: "",
    cost: "",
    price: "",
    quantity: "",
    type: ""
  })

  useEffect(() => {
    if(!isLoading){
      setItem(data)
      console.log(data)
    } // eslint-disable-next-line
  }, [isLoading])

  const onChange = (e) => {
    const {name, value} = e.target
    if (e.target.name === ("cost" || "price")) {
      setItem((prevstate) => ({
        ...prevstate,
        [name]: parseFloat(value)
      }))
    } else {
    setItem((prevstate) => ({
      ...prevstate,
      [name]: value
    }))
  }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(item)
    await editItem(item)
    alert(`${item.name} ได้ถูกแก้ไข`)
    navigate('/inventory')
  }

  const handleDelete = async (e) => {
    let confirm = prompt(`พิมพ์ ${item.name} ลงในช่องว่างเพื่อยืนยันการลบ`)
      if (confirm.toLocaleLowerCase() === `${item.name}`.toLocaleLowerCase()) {
        try
          {
            await deleteItem({barcode})
            alert(`${item.name} ได้ถูกลบออกจากระบบ`)
          } catch (error) {
              alert("ไม่สามารถทำการลบได้")
          }
          navigate("/inventory")
        } else {
          alert("ชื่อไม่ถูกต้อง\nยกเลิกการลบ")
        }
  }

  const init = () => {
    setItem(data)
  }

  let content;

    if (isLoading) {
      content = <p>"Loading...</p> 
    } else if (isSuccess) { 
      content = (
        <section className="container-fluid col-7 mt-3">
          <h3>แก้ไข</h3>
          <form onSubmit={handleSubmit}>
            
            <label htmlFor="name">ชื่อสินค้า</label>
            <input type="text" value={item.name}  name="name" onChange={onChange}></input>
             
            <label htmlFor="barcode">Barcode</label>
            <input disabled type="text" value={item.barcode}  name="barcode" onChange={onChange}></input>

            <label htmlFor="quantity">ประเภทสินค้า</label>
                <ToggleButtonGroup type="radio" value={item.category} name="category" className="d-flex justify-content-around m-1">
                  <ToggleButton id="category_etc" variant="outline-primary" value={0} onChange={onChange}>อื่นๆ</ToggleButton>
                  <ToggleButton id="category_drinks" variant="outline-primary" value={1} onChange={onChange}>เครื่องดื่ม</ToggleButton>
                  <ToggleButton id="category_snacks" variant="outline-primary" value={2} onChange={onChange}>ขนม</ToggleButton>
                  <ToggleButton id="category_gifts" variant="outline-primary" value={3} onChange={onChange}>ของฝาก</ToggleButton>
                  <ToggleButton id="category_meds" variant="outline-primary" value={4} onChange={onChange}>ยา</ToggleButton>
                </ToggleButtonGroup>
             
            <label htmlFor="variation_1">คำอธิบาย 1</label>
            <input type="text" value={item.variation_1}  name="variation_1" onChange={onChange}></input>
             
            <label htmlFor="variation_2">คำอธิบาย 2</label>
            <input type="text" value={item.variation_2}  name="variation_2" onChange={onChange}></input>
             
            <label htmlFor="variation_3">คำอธิบาย 3</label>
            <input type="text" value={item.variation_3}  name="variation_3" onChange={onChange}></input>
             
            <label htmlFor="cost">ต้นทุน</label> 
            <input type="number" value={item.cost}  name="cost" step={0.01} onChange={onChange}></input>
             
            <label htmlFor="price">ราคาขาย</label>
            <input type="number" value={item.price}  name="price" step={0.01} onChange={onChange}></input>
             
            <label htmlFor="quantity">จำนวนสินค้า</label>
            <ButtonGroup className='d-flex mt-1' size='sm'>
              <input type="number" name='quantity' value={item.quantity} onChange={onChange} className='text-center' placeholder={"Currently " + item.quantity}></input>
              <ToggleButton type="radio" id="direction_ADD" name="direction" variant="outline-success" value={1} onChange={onChange}>เพิ่ม</ToggleButton>
              <ToggleButton type="radio" id="direction_REMOVE" name="direction" variant="outline-danger" value={-1} onChange={onChange}>ลด</ToggleButton>
            </ButtonGroup>
            
            <div className='text-center'>
              <Button className='m-4' variant="primary" type='submit'>ยืนยัน</Button>
              <Button className='m-4' variant="info" onClick={init}>ค่าเริ่มต้น</Button>
              <Button className='m-4' variant="danger" onClick={handleDelete}>ลบรายการ</Button>
            </div>
          </form>
        </section>
      )  
    } else if (isError) {
      content = JSON.stringify(error)
    }


return content


}

export default InventoryEdit