import { useState, useRef } from 'react'
import Table from 'react-bootstrap/esm/Table';
import Button from 'react-bootstrap/esm/Button';
import { useGetSellQuery, useSellItemMutation, useReverseItemMutation } from './inventoryApiSlice'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../authSlice';
import Clock from '../../components/Clock'

const InventorySell = () => {

    const cashier = useSelector(selectCurrentUser)
    const [ sellItem ] = useSellItemMutation()
    const [ reverseItem ] = useReverseItemMutation()
    const inputRef = useRef()
    const [ itemList, setItemList ] = useState([])
    const [ barcode, setBarcode ] = useState('')
    const [ cart, setCart ] = useState([])
    const [ sum, setSum ] = useState(0)
    
    const {
        data: cat,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSellQuery()

    const handleItem = () => {
        cat.forEach(element => {
            if ((element.barcode).toString() === barcode) {
              console.log("Found: ", element.name)
              setItemList(itemList => [...itemList, element])
              setCart(cart => [...cart, element])
              setSum(sum + Number(element.price))
            }
          });
    }

    const handleReset = () => {
        setBarcode()
        setItemList([])
        setSum(0)
        setCart([])
    }

    const handleSell = async () => {
        if (cart.length >= 1) {
            let order = {}
            order = cart
            try {
                const sell = await sellItem(order)
                console.log(sell)
            } catch (error) {
                console.log({message: error.message})
            }
            window.confirm("ยืนยันการขาย")
            handleReset()
        } 
    }

    const handleReverse = async () => {
        if (cart.length >= 1) {
            let order = {}
            order.barcode = cart
            try {
                await reverseItem(order)
                console.log("REVERSE ITEMS ", order.barcode)
            } catch (error) {
                console.log({message: error.message})
            }
            window.confirm("ยืนยันยกเลิกการขาย")
            handleReset()
        } 
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            if (barcode === 'clearcart') {
                handleReset()
            } else if (barcode === 'confirm') {
                handleSell()
                handleReset()
            } else if (barcode === 'reverse') {
                handleReverse()
                handleReset()
            } else if (barcode === 'reload') {
                window.location.reload();
            } else {
                handleItem()
            }
            e.target.value = null
        }
    }

    const showCart = [...itemList].reverse().map((item, index) => {
        return(
            <tr key={index}>
                <td>{item.name}</td>
                <td>{item.variation_1 + ' ' + item.variation_2 + ' ' + item.variation_3}</td>
                <td>{item.price}</td>
            </tr>
        )
    })

    let content;
    if (isLoading) {
        content = <h1>"Loading...</h1>
    } else if (isSuccess) {
        content = (
            <section className='container-fluid d-flex'>
                <div className='col-9'>
                    <Table style={{color:"white"}} size='lg'>
                        <thead>
                            <tr>
                                <td>รายการ</td>
                                <td>คำอธิบาย</td>
                                <td>ราคา</td>
                            </tr>
                        </thead>
                        <tbody id='selltable'>
                            {showCart}
                        </tbody>
                    </Table>
                </div>
                <div className='col-3 text-center mt-4'>
                    <div className='d-flex justify-content-around mb-5' style={{fontSize: '2rem'}}>
                        {cashier.name}
                        <Clock />
                    </div>
                    <h1>จำนวน</h1>
                    <h1>{cart ? cart.length : '0'}</h1>
                    <br></br>
                    <h1>ราคารวม</h1>
                    <h1 style={{color: '#7fff00'}}>{sum}</h1>
                    <input autoFocus ref={inputRef} id='sell' type='text' className='border-0' onKeyDown={handleKeyDown} onChange={e => {setBarcode(e.target.value)}}/>
                    <div className='d-grid gap-5'>
                        <Button style={{height:'70px', fontSize:'2em'}} variant='danger' onClick={handleReverse}>ยกเลิกการขาย</Button>
                        <Button style={{height:'70px', fontSize:'2em'}} variant='success' onClick={handleSell}>ขาย</Button>
                        <Button style={{height:'70px', fontSize:'2em'}} variant='danger' onClick={handleReset}>ล้าง  </Button>
                    </div>
                </div>

            </section>
        )
    } else if (isError) {
        content = <p>{JSON.stringify(error)}</p>
    }
    
  return (
    content
  )

}

export default InventorySell