import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {useGetItemByBarcodeQuery} from './inventoryApiSlice'

const ItemCheck = () => {

  const [barcode, setBarcode] = useState('')

  const { data } = useGetItemByBarcodeQuery(barcode)

  const navigate = useNavigate()
  
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      setTimeout(() => {
        handleSubmit()
      }, 100);
    }}
  
  const handleSubmit = async () => {
    const json = await data
    console.log(json)
    if (json.name !== '') {
      alert("มีสินค้าในระบบ\nกำลังไปหน้าแก้ไขรายการ")
      navigate(`/edit/${json.barcode}`)

    } else {
      if (window.confirm("ไม่มีสินค้าในระบบ\nกำลังไปหน้าเพิ่มสินค้า")) {
        navigate("/add")
      } else {
        setBarcode('')
      }
    
    }
    setBarcode('')
  }

  let content = (
      <div className='container col-8 mt-5' >
        <input autoFocus value={barcode} onSubmit={handleSubmit} onKeyDown={handleKeyDown} onChange={e => {setBarcode(e.target.value)}} name='barcode' type="number" required/>
        <label htmlFor='barcode'>Barcode</label>
      </div>
  )

  return (
    content
  )
}

export default ItemCheck