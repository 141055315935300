import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentUser } from "../authSlice";
import NaivigationBar from '../../components/NavigationBar'

const RequiredAuth = ({ allowedRoles }) => {
    const token = useSelector(selectCurrentToken)
    const user = useSelector(selectCurrentUser)
    const location = useLocation()

    return (
        user?.role?.find(acc => allowedRoles?.includes(acc))
            ? <div>
                <NaivigationBar />
                <Outlet />
            </div>
            : token
                ? <Outlet />
                : <Navigate to="/" state={{ from: location }} replace />
    )
}

export default RequiredAuth