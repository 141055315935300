import React from 'react'
import {Link} from 'react-router-dom'


const NavigationBar = () => {
  return (
  <>
  <div className='nav'>
    <ul>
      <li>
        <Link to='/sell'>ร้านค้า</Link>
      </li>
      <li>
        <Link to='/inventory'>คลังสินค้า</Link>
      </li>
      <li>
        <Link to='/add'>เพิ่มสินค้า</Link>
      </li>
      <li>
        <Link to='/check'>ตรวจสอบสินค้า</Link>
      </li>
      <li>
        <Link to='/hard'>สินค้ายิงยาก</Link>
      </li>
      <li>
        <Link to='/bulk'>สินค้ายกลัง</Link>
      </li>
    </ul>
  </div>
  </>
  )
}

export default NavigationBar