import { useAddInventoryMutation } from "./inventoryApiSlice";
import { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

const InventoryAdd = () => {
    const navigate = useNavigate()
    const [form, setForm] = useState({
        "name": '',
        "barcode": '',
        "variation_1": '',
        "variation_2": '',
        "variation_3": '',
        "cost": '',
        "type": "i",
        "price": '',
        "quantity": '',
        "category": 0
      })

      const onChange = (e) => {
        const {value, name } = e.target;
    
        setForm((state) => ({
          ...state,
          [name]: value
        }))
      }

    const [additem] = useAddInventoryMutation()

    const handleSubmit = (e) => {
        e.preventDefault()
        additem(form)
        setForm('')
        alert(`${form.name} ได้ถูกบันทึก`)
        navigate('/inventory')

    }

    let content = (
        <div className='container col-7 mt-3'>
            <h3>เพิ่มสินค้า</h3>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">ชื่อสินค้า</label>
                <input value={form.name} onChange={onChange} name='name' type="text" required/>

                <label htmlFor="barcode">Barcode</label>
                <input value={form.barcode} onChange={onChange} name='barcode' type="number" required/>

                <label htmlFor="quantity">ประเภทสินค้า</label>
                <ToggleButtonGroup type="radio" name="category" value={form.category} className="d-flex justify-content-around m-1">
                  <ToggleButton id="category_etc" variant="outline-primary" value={0} onChange={onChange}>อื่นๆ</ToggleButton>
                  <ToggleButton id="category_drinks" variant="outline-primary" value={1} onChange={onChange}>เครื่องดื่ม</ToggleButton>
                  <ToggleButton id="category_snacks" variant="outline-primary" value={2} onChange={onChange}>ขนม</ToggleButton>
                  <ToggleButton id="category_gifts" variant="outline-primary" value={3} onChange={onChange}>ของฝาก</ToggleButton>
                  <ToggleButton id="category_meds" variant="outline-primary" value={4} onChange={onChange}>ยา</ToggleButton>
                </ToggleButtonGroup>
  
                <label htmlFor="variation_1">คำอธิบาย 1</label>
                <input value={form.variation_1} onChange={onChange} name='variation_1' type="text" required />
                
                <label htmlFor="variation_2">คำอธิบาย 2</label>
                <input value={form.variation_2} onChange={onChange} name='variation_2' type="text" />
                
                <label htmlFor="variation_3">คำอธิบาย 3</label>
                <input value={form.variation_3} onChange={onChange} name='variation_3' type="text"/>
                
                <label htmlFor="cost">ต้นทุน</label>
                <input value={form.cost} onChange={onChange} name='cost' step={0.01} type="number"required/>
                
                <label htmlFor="price">ราคาขาย</label>
                <input value={form.price} onChange={onChange} name='price' step={0.01} type="number" required/>
                
                <label htmlFor="quantity">จำนวนสินค้า</label>
                <input value={form.quantity} onChange={onChange} name='quantity' type="number" required />
                
                <div className='text-center'>
                    <Button className='m-4' variant="primary" type='submit'>ยืนยัน</Button>
                    <Button className='m-4' variant="danger" type="reset">ยกเลิก</Button>
                </div>
            </form>
        </div>
    )

  return content
}

export default InventoryAdd




