import { apiSlice } from "../../app/api/apiSlice";

export const inventoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInventory: builder.query({
            query: () => '/inventory',
            providesTags: ['Inventory']
        }),
        getSell: builder.query({
            query: () => '/sell',
            providesTags: ['Sell']
        }),
        sellItem: builder.mutation({
            query: (order) => ({
                url: '/sell',
                method: 'POST',
                body: order
            }),
            invalidatesTags: ['Inventory']
        }),
        addInventory: builder.mutation({
            query: (item) => ({
                url: '/inventory',
                method: 'POST',
                body: item
            }),
            invalidatesTags: ['Inventory']
        }),
        deleteItem: builder.mutation({
            query: ({ barcode }) =>({
                url: `/inventory/${barcode}`,
                method: 'DELETE',
                body: barcode
            }),
            invalidatesTags: ['Inventory']
        }),
        getItemByBarcode: builder.query({
            query: (barcode) => `/inventory/${barcode}`,
            providesTags: ['Inventory']
        }),
        editItemByBarcode: builder.mutation({
            query: (item) => ({
                url: `/inventory/${item.barcode}`,
                method: 'POST',
                body: item
            }),
            invalidatesTags: ['Inventory']
        }),
        reverseItem: builder.mutation({
            query: (order) => ({
                url: '/reverse',
                method: 'POST',
                body: order
            }),
            invalidatesTags: ['Inventory']
        }),
        incrementItem: builder.mutation({
            query: (item) => ({
                url: '/management',
                method: 'POST',
                body: item
            }),
            invalidatesTags: ['Inventory']
        }),
        getHardItems: builder.query({
            query: () => '/management/hard',
            providesTags: ['Hard']
        }),
        editHardItems: builder.mutation({
            query: (barcodes) => ({
                url: '/management/hard',
                method: 'POST',
                body: barcodes
            }),
            invalidatesTags: ['Hard']
        }),
        getBulkItems: builder.query({
            query: () => '/bulk',
            providesTags: ['Bulk']
        }),
        createBulk: builder.mutation({
            query: (bulk) => ({
                url: '/bulk',
                method: 'POST',
                body: bulk
            }),
            invalidatesTags: ['Bulk']
        }),
        deleteBulk: builder.mutation({
            query: (barcode) => ({
                url: '/bulk',
                method: 'DELETE',
                body: barcode
            }),
            invalidatesTags: ['Bulk']
        })
    })
})

export const {
    useGetInventoryQuery,
    useAddInventoryMutation,
    useDeleteItemMutation,
    useGetItemByBarcodeQuery,
    useEditItemByBarcodeMutation,
    useGetSellQuery,
    useSellItemMutation,
    useReverseItemMutation,
    useIncrementItemMutation,
    useEditHardItemsMutation,
    useGetHardItemsQuery,
    useGetBulkItemsQuery,
    useCreateBulkMutation,
    useDeleteBulkMutation
} = inventoryApiSlice