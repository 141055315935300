import {Routes, Route} from 'react-router-dom'
import Layout from './components/Layout'
import InventoryList from './features/inventory/InventoryList'
import InventoryHard from './features/inventory/InventoryHard'
import BulkItems from './features/inventory/InventoryPack'
import EditItem from './features/inventory/InventoryEdit'
import AddItem from './features/inventory/InventoryAdd'
import SellItem from './features/inventory/InventorySell'
import CheckItem from './features/inventory/ItemCheck'
import Login from './features/auth/Login'
import RequiredAuth from './features/auth/RequiredAuth'


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Route */}
        <Route index element={<Login />} />

        {/* Protected Routes */}
        <Route element={<RequiredAuth allowedRoles={process.env.REACT_APP_ALLOWED}/>}>
          <Route path="inventory" element={<InventoryList />} />
          <Route path="hard" element={<InventoryHard />} />
          <Route path="sell" element={<SellItem />} />
          <Route path="add" element={<AddItem />} />
          <Route path="bulk" element={<BulkItems />} />
          <Route path="edit/:barcode" element={<EditItem />} />
          <Route path="check" element={<CheckItem />} />
        </Route>
        
      </Route>
    </Routes>
  )
}

export default App;
